import React from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const StyledDiv = styled.div`
  ${tw`relative
overflow-hidden
text-white
container
`}
  & h1 {
    max-width: 60%;
    font-size: 3vw;
    line-height: 3vw;
    text-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
  }
`

export default function HeroBlog(props) {
  const { image, heading } = props.content

  const heroImage = getImage(
    image[0]?.localFile?.childImageSharp?.gatsbyImageData
  )

  return (
    <section
      css={tw`sm:mt-40 xl:mt-64 flex justify-center items-center`}
      className="blog-hero"
    >
      <StyledDiv>
        {heroImage && (
          <GatsbyImage
            image={heroImage}
            alt={image[0]?.title}
            layout="constrained"
          />
        )}
        <div
          dangerouslySetInnerHTML={{ __html: heading }}
          css={tw`absolute left-14 bottom-14`}
        ></div>
      </StyledDiv>
    </section>
  )
}
